<template>
  <div>
    <v-app-bar
      app
      dense
      dark
      :style="{
        backgroundColor: mdAndUp ? 'rgba(255, 255, 255, 0.9)' : 'transparent',
      }"
      elevation="0"
    >
      <!-- links -->
      <v-spacer></v-spacer>
      <v-toolbar-items
        class="hidden-sm-and-down"
        style="border-bottom: 1px solid #ccc"
      >
        <v-btn
          :color="currentSection.title == item.title ? '#42634b' : 'black'"
          dark
          v-for="(item, index) in menu"
          :key="index"
          :href="item.link"
          class="no-underline"
          text
          :style="{
            fontWeight: currentSection.title == item.title ? 'bold' : '',
          }"
          >{{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn
        color="black"
        dark
        icon
        @click="showMenu = !showMenu"
        class="hidden-md-and-up"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="showMenu"
      temporary
      right
      app
      class="hidden-md-and-up"
    >
      <v-list nav>
        <v-list-item
          v-for="(item, index) in menu"
          :key="index"
          link
          :href="item.link"
          class="no-underline"
          :style="{
            fontWeight: currentSection.title == item.title ? 'bold' : '',
            color: currentSection.title == item.title ? '#42634b' : 'black',
          }"
        >
          <v-list-item-icon v-if="false">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  computed: {
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  data() {
    return {
      top: 0,
      currentSection: { title: "" },
      isMobile: false,
      isTablet: false,
      showMenu: false,
      menu: [
        { title: "Portada", link: "#home", icon: "mdi-home" },
        { title: "Info", link: "#info", icon: "mdi-home" },
        { title: "Logística", link: "#logistica", icon: "mdi-home" },
        {
          title: "Confirmar Asistencia",
          link: "#asistencia",
          icon: "mdi-home",
        },
        {
          title: "Música",
          link: "#musica",
          icon: "mdi-home",
        },
        /* {
          title: "Dónde alojarse",
          link: "#alojamientos",
          icon: "mdi-home",
        },
        */
        {
          title: "Lista de bodas",
          link: "#lista-boda",
          icon: "mdi-home",
        },
      ],
    };
  },
  methods: {
    onScroll() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      this.top = { top };

      let section = null;
      for (let i = 0; i < this.menu.length; i++) {
        const m = this.menu[i];
        const id = m.link.replace("#", "");
        let e = document.getElementById(id).offsetTop;
        this.top[m.title] = { id: id, e };

        if (top >= e - 10) {
          section = m;
        }
      }
      this.currentSection = section;
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
      this.isTablet = window.innerWidth >= 600 && window.innerWidth < 960;
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    window.addEventListener("scroll", this.onScroll);
    this.onScroll();
  },
};
</script>

<style lang="scss" scoped>
//960px
</style>
