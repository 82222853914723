<template>
  <div>
    <h1>This is a test</h1>
    <v-btn color="primary" depressed @click="test">Test</v-btn>
    <div>
      <h2>result</h2>
      {{ result }}
    </div>
  </div>
</template>

<script>
import api from "@/assets/api";
export default {
  data() {
    return {
      result: "n/a",
    };
  },
  methods: {
    async test() {
      let x = await api.apiFetch("/test");
      if (x.ok) {
        this.result = await x.json();
      } else {
        this.result = "error";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
