<template>
  <div>
    <!-- authenticated -->
    <div v-if="token" class="auth-box">
      <h2 style="margin-bottom: 10px">
        Welcome, {{ username }}
        <v-btn text color="primary" @click="logout()">Cerrar sesión</v-btn>
      </h2>

      <p>Usa el menú de arriba a la derecha para ir a la página que quieras.</p>
    </div>

    <!-- log-in -->
    <div v-else class="auth-box">
      <v-text-field
        label="Usuario"
        type="text"
        ref="usernameField"
        @focus="$event.target.select()"
        @keydown.enter="$refs.passwordField.focus()"
        @keydown.tab.prevent="$refs.passwordField.focus()"
        v-model="user"
        clearable
      ></v-text-field>
      <v-text-field
        label="Contraseña"
        type="password"
        ref="passwordField"
        @focus="$event.target.select()"
        @keydown.enter="login()"
        v-model="password"
        clearable
      ></v-text-field>
      <v-btn :loading="loading" block depressed color="primary" @click="login()"
        >Login</v-btn
      >

      <div v-if="message" :style="{ color: message_color, marginTop: '20px' }">
        {{ message }}
      </div>
    </div>
    <v-btn v-if="false" class="mt-5" depressed color="primary" @click="test()"
      >test</v-btn
    >
  </div>
</template>

<script>
import api from "@/assets/api";
import { mapGetters, mapState } from "vuex";
import { mutations } from "@/store/types";
export default {
  data() {
    return {
      user: "",
      password: "",

      loading: false,

      message: "",
      message_color: "black",
    };
  },

  computed: {
    ...mapGetters(["username"]),
    ...mapState(["token"]),
  },

  methods: {
    async test() {
      let x = await api.apiFetch("/test", {
        method: "POST",
        body: JSON.stringify({ user: this.user, password: this.password }),
      });
      console.log(x);
    },
    logout() {
      this.$store.commit(mutations.SET_TOKEN, "");
    },
    async login() {
      this.loading = true;
      this.message = "";
      this.message_color = "default";

      let x = await api.apiFetch("/auth", {
        method: "POST",
        body: JSON.stringify({ user: this.user, password: this.password }),
      });

      if (x.status == 200) {
        let x2 = await x.json();
        this.$store.commit(mutations.SET_TOKEN, x2.token);
        this.message = "Todo correcto!";
        this.message_color = "green";

        //redirect to original page, if any
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        }
      } else {
        this.message = "Error";
        this.message_color = "red";
        //this.user = "";
        this.password = "";
        this.$refs.usernameField.focus();
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-box {
  margin: auto;
  max-width: 500px;
  max-height: 800px;
  background-color: white;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(200, 200, 200, 0.2);
}
</style>
