var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{style:({
      backgroundColor: _vm.mdAndUp ? 'rgba(255, 255, 255, 0.9)' : 'transparent',
    }),attrs:{"app":"","dense":"","dark":"","elevation":"0"}},[_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down",staticStyle:{"border-bottom":"1px solid #ccc"}},_vm._l((_vm.menu),function(item,index){return _c('v-btn',{key:index,staticClass:"no-underline",style:({
          fontWeight: _vm.currentSection.title == item.title ? 'bold' : '',
        }),attrs:{"color":_vm.currentSection.title == item.title ? '#42634b' : 'black',"dark":"","href":item.link,"text":""}},[_vm._v(_vm._s(item.title)+" ")])}),1),_c('v-spacer'),_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"color":"black","dark":"","icon":""},on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1),_c('v-navigation-drawer',{staticClass:"hidden-md-and-up",attrs:{"temporary":"","right":"","app":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{attrs:{"nav":""}},_vm._l((_vm.menu),function(item,index){return _c('v-list-item',{key:index,staticClass:"no-underline",style:({
          fontWeight: _vm.currentSection.title == item.title ? 'bold' : '',
          color: _vm.currentSection.title == item.title ? '#42634b' : 'black',
        }),attrs:{"link":"","href":item.link}},[(false)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }