<template>
  <div>
    <div class="collapsible" @click="expanded = !expanded">
      <h3>
        {{ title }}
        <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </h3>
    </div>
    <v-expand-transition>
      <div v-if="expanded">
        <slot></slot>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.collapsible {
  cursor: pointer;
  /*padding-top: 8px;*/
  /*padding-bottom: 8px;*/
}
.collapsible:hover {
  /*background-color: rgba(130, 146, 134, 0.2);*/
}
</style>
