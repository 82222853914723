import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { mutations } from "./types";

const store = new Vuex.Store({
  state: {
    count: 0,
    token: localStorage.token || "",
  },
  getters: {
    username: (state) => {
      return state.token.split("::")[0];
    },
  },
  mutations: {
    [mutations.SET_TOKEN](state, value) {
      state.token = value;
      localStorage.token = value; //let's store it for a while
    },
  },
  actions: {
    somethingAsync({ commit }, value) {
      commit(mutations.SET_TEST1, value);
    },
  },
  modules: {},
});

export default store;
