<template>
  <v-app color="transparent">
    <v-main>
      <TheHeader />

      <v-container
        style="
          margin: 0px;
          padding: 0px;
          min-width: 100%;
          scroll-snap-type: y mandatory;
        "
      >
        <router-view></router-view>
      </v-container>
      <!-- some buffer at the bottom -->
    </v-main>
  </v-app>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
export default {
  components: {
    TheHeader,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.cdnfonts.com/css/dancing-script");
* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-family: "Prata", Georgia, "Times New Roman", serif;
  color: #42634b;
}

.section-title {
  padding-top: 150px;
  padding-left: 200px;
}
h1 {
  font-size: 45pt;
  line-height: normal;
  padding-left: 65px;
}
.subheader {
  font-size: 30pt;
}

@media (max-width: 768px) {
  h1 {
    font-size: 30pt;
  }
  .section-title {
    padding-left: 50px;
    padding-right: 20px;
  }
  .subheader {
    font-size: 15pt;
    padding-left: 90px;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 20pt;
  }

  .section-title {
    padding-left: 50px;
  }
  .subheader {
    font-size: 15pt;
    padding-left: 50px;
  }
}

h3 {
  margin-top: 30px;
}
//-----------------------
.scroll-container {
  //max-width: 850px;
  //height: 600px;
  height: calc(100vh - 0px);

  overflow: auto;
  -webkit-scroll-snap-type: y proximity;
  -ms-scroll-snap-type: y proximity;
  scroll-snap-type: y proximity;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
//---------------
section {
  scroll-snap-align: start;

  position: relative;
  //opacity: 0.65;
  //background-color: rgba(0, 0, 0, 0.65);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 60%;
  min-height: 60vh;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(100, 100, 100, 0.2);
}

#home {
  min-height: 100%;
  min-height: 100vh;
  padding-bottom: 0px;
}

.section-bg {
  opacity: 0.3;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left center;
}

.hero-1 {
  height: 100vh;
  width: 100%;
  opacity: 0.3;
  background-image: url("@/assets/img/fei/main1.jpg");
  background-size: cover;
  background-position: 50% 60%; /* Adjust based on the coordinate */
  background-repeat: no-repeat;
}

/* Optional: For fine-tuning on mobile */
@media (max-width: 768px) {
  .hero-1 {
    background-position: 50% 50%; /* Fine-tune for mobile devices */
  }
}

.hero-2 {
  height: 100vh;
  width: 100%;
  max-width: 800px;
  margin: auto;
  opacity: 0.3;
  background-image: url("@/assets/img/fei/main2-2.png");
  background-size: cover;
  background-position: 50% 0%; /* Adjust based on the coordinate */
  background-repeat: no-repeat;
}

/* Optional: For fine-tuning on mobile */
@media (max-width: 768px) {
  .hero-2 {
    background-position: 50% 50%; /* Fine-tune for mobile devices */
  }
}

.section-content {
  max-width: 920px;
  margin: auto;
  padding: 60px 20px 0px 20px;
  padding-bottom: 30px;
}
.section-title {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
  width: 100%;
  text-align: center;
}

.main {
  margin-top: 40px;
}
html {
  scroll-behavior: smooth;
}

p {
  margin-top: 10px;
}

.v-main {
  padding-top: 0px !important;
}

a,
.link {
  cursor: pointer;
  color: #0070d7;
  font-weight: 500;
  /*font-size: 10pt;*/
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

//------------

.mcols {
  display: flex;
  justify-content: space-evenly;
  padding: 0px;
  flex-wrap: wrap;
  height: 100%;

  background-color: white;
  max-width: 1200px;
  margin: auto;
}
.mcol {
  width: 45%;
  padding: 30px;
  margin: 2%;
  max-width: 100%;
  background-color: #42634b;
  border-radius: 8px;
  color: white;
  * {
    color: white;
  }
}
.mimg {
  object-fit: cover;
  width: 100%;
}
@media (max-width: 768px) {
  .mcol {
    width: 100%;
    margin: 0px;
    margin-top: 10px;
    border-radius: 0px;
  }
  .mleft {
    object-position: -250px;
  }
}

@media (min-width: 768px) {
  .mleft {
    /*object-position: -90px;*/
  }
}
//-------------------------

.button {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #42634b;
  color: white !important;
  text-align: center;
}
.no-underline:hover {
  text-decoration: none;
}
li {
  margin-bottom: 8px;
}
</style>
