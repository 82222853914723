import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AuthView from "../views/AuthView.vue";
import TestView from "../views/TestView.vue";
import AsistenciaView from "@/views/AsistenciaView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/test",
    name: "test",
    component: TestView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asistencia",
    name: "asistencia",
    component: AsistenciaView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.meta?.requiresAuth) {
    const token = localStorage.token;
    if (!token) next("/auth?redirect=" + to.path);
    else next();
  } else next();
});

export default router;
