<template>
  <div>
    <!-- <v-row justify="center"> -->
    <v-btn
      :color="button_color"
      :loading="loading"
      dark
      @click.stop="dialog = true"
      block
      large
      depressed
    >
      {{ button_text }}
    </v-btn>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ text }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :color="color1"
            text
            @click="button1"
            v-if="text1 != ''"
            depressed
            >{{ text1 }}</v-btn
          >
          <v-btn
            :color="color2"
            text
            @click="button2"
            v-if="text2 != ''"
            depressed
          >
            {{ text2 }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- </v-row> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    button_color: {
      type: String,
      default: "primary",
    },
    button_text: {
      type: String,
      default: "Open Dialog",
    },
    title: {
      type: String,
      default: "Wait a minute!",
    },
    text: {
      type: String,
      default: "Do you want to continue?",
    },
    loading: {
      type: Boolean,
      default: false,
    },

    color1: {
      type: String,
      default: "red",
    },
    color2: {
      type: String,
      default: "blue",
    },
    text1: {
      type: String,
      default: "Cancel",
    },
    text2: {
      type: String,
      default: "Confirm",
    },
  },
  methods: {
    button1() {
      this.dialog = false;
      this.$emit("button1");
    },
    button2() {
      this.dialog = false;
      this.$emit("button2");
    },
  },
};
</script>

<style lang="scss" scoped></style>
