import router from "./../router/index";
import store from "./../store/index";

const base_url = process.env.VUE_APP_API_URL;

async function whoami() {
  let x = await apiFetch("/whoami");
  if (x.ok) {
    return await x.json();
  } else {
    return false;
  }
}

async function apiFetch(path, options) {
  console.log("[api][apiFetch] start");

  if (!path.startsWith("/")) path = "/" + path; //just in case
  const url = base_url + path;

  const token = store.state.token;

  if (!options) options = { headers: {} };
  if (!options.headers) options.headers = {};
  //options.mode = 'no-cors'
  if (token) {
    options.headers["xvi-token"] = token;
  }

  const x = await fetch(url, options);

  if (x.status == 403) {
    localStorage.token = "";
    if (router.currentRoute.path != "/auth") {
      router.push("/auth?redirect=" + router.currentRoute);
    }
  }
  return x;
}

export default { apiFetch, whoami };
