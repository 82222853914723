<template>
  <div>
    <!-- PORTADA -->

    <section id="home" style="max-width: 1200px; margin: auto">
      <img
        v-if="false"
        src="@/assets/img/fei/main1.jpg"
        :alt="pareja"
        class="section-bg"
        style="height: 100%; width: 100%; object-position: -100px 0"
      />
      <div class="hero-1"></div>
      <!-- :style="{
          backgroundImage: `url(${require('@/assets/img/fei/main1.jpg')})`,
        }"
        -->

      <div class="section-title" style="margin-top: 80px">
        <h1>{{ pareja }}</h1>
        <p class="subheader">{{ fecha }}</p>
      </div>
    </section>

    <!-- info -->
    <div id="info">
      <div
        style="
          max-width: 900px;
          margin: auto;
          padding-top: 30px;
          text-align: center;
        "
      ></div>
      <div class="mcols">
        <!-- intro -->
        <div style="width: 100%">
          <div
            style="
              padding: 15px 15px 0px 15px;

              max-width: 600px;
              margin: auto;
            "
          >
            <h2>¡¡NOS CASAMOS!!</h2>
          </div>
          <div
            style="padding: 15px 15px 0px 15px; max-width: 600px; margin: auto"
          >
            <p>
              Estamos felices y muy ilusionados, pero todavía lo estaremos más
              si podemos contar contigo el 17 de Mayo de 2025
            </p>

            <p>
              Sabemos que aún quedan meses para la fecha, pero tenemos muchas
              ganas y todavía hay mucho que organizar. Lo más importante de
              todo: si te hemos mandado ya este enlace es porque eres
              imprescindible para nosotros y no queremos disfrutar de ese día
              sin ti.
            </p>

            <p>¡Guárdate la fecha!</p>
            <p>Sábado 17 de Mayo de 2025 a las 13:30h.</p>
          </div>
          <!--
          <div style="padding: 15px 30px 30px 30px">
            <a
              style="max-width: 500px; margin: auto"
              class="button no-underline"
              href="https://docs.google.com/forms/d/e/1FAIpQLScaaoTqQzFASKS27BxwHT5iFEEx_ts0g3n3j2X1WPV252Uo2g/viewform"
              target="_blank"
              rel="noopener noreferrer"
              >CONFIRMAR ASISTENCIA</a
            >
          </div>

          
        --></div>
        <!-- box1 -->
        <div class="mcol">
          <h2>Iglesia Catedral de Las Fuerzas Armadas de Madrid</h2>
          <p>La Misa empezará a las 13:30h</p>

          <p>
            <a
              href="https://maps.app.goo.gl/qtZjpxk9sviEz6UDA"
              style="color: #6cb3f9; font-weight: 900"
              target="_blank"
              ><v-icon color="#FF756B" class="mb-1">mdi-map-marker</v-icon
              >Google Maps</a
            >
          </p>

          <div>
            <img
              src="@/assets/img/fei/iglesia-1.jpg"
              alt="Iglesia Catedral de Las Fuerzas Armadas de Madrid"
              class="mimg"
            />
          </div>
        </div>
        <!-- box2 -->
        <div class="mcol">
          <h2>Finca La Tayada</h2>
          <p>
            La celebración tendrá lugar en la Finca La Tayada, a 35 minutos de
            la Iglesia y a 25-30 minutos del centro de Madrid.
          </p>

          <p>
            <a
              href="https://maps.app.goo.gl/T2vRyvZnC9nReQ4o7"
              style="color: #6cb3f9; font-weight: 900"
              ><v-icon color="#FF756B" class="mb-1">mdi-map-marker</v-icon
              >Google Maps</a
            >
          </p>
          <div>
            <img
              src="@/assets/img/fei/sitio-1.jpg"
              alt="Finca La Tayada"
              class="mimg"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- logistica -->
    <section id="logistica">
      <div class="section-content">
        <h2>Consejos de logística</h2>

        <p>
          A continuación te dejamos algunas ideas que os ayuden un poco con la
          logística.
        </p>

        <CollapsibleH3 title="Aviso a conductores">
          <p>
            Los vehículos sin etiqueta ambiental, NO PUEDEN ENTRAR EN MADRID
            (Dentro de la M30).
          </p>
          <p>
            La ubicación de la Iglesia está en “Madrid Central”, y no está
            permitido aparcar en la calle (únicamente los vehículos con etiqueta
            ECO o Azul). El parking más cercano es el de La Plaza Mayor, ubicado
            a 500 metros de la Iglesia.
          </p>
          <p>
            La entrada a la Finca desde la autopista no tiene carril de
            desviación y el giro es muy brusco. Cuando te estés aproximando, te
            recomendamos bajar mucho la velocidad y poner el doble intermitente.
          </p>
          <p>
            Dentro de la finca hay parking suficientemente grande, por lo que no
            tendrás problemas para aparcar.
          </p>
        </CollapsibleH3>

        <CollapsibleH3 :title="'¿Conoces Madrid?'">
          <p>
            Si vienes de fuera y no conoces el centro de Madrid, tal vez te
            apetezca llegar con un poquito de antelación y darte una vuelta por
            la zona, ya que el Palacio Real y la Basílica de la Almudena están a
            tan sólo 150 metros de La Iglesia. ¡Merecen la pena!
          </p>
        </CollapsibleH3>

        <CollapsibleH3 title="Hoteles" v-if="false">
          <p>Los hoteles de madrid tienen entre 1 y 5 estrellas.</p>
        </CollapsibleH3>

        <CollapsibleH3 title="Autobuses">
          <p>
            Habrá servicio de autobuses al final de la ceremonia desde la
            iglesia al lugar de celebración, y después de vuelta a Madrid. Más
            adelante especificaremos las paradas.
          </p>
        </CollapsibleH3>
      </div>
    </section>
    <!-- consejos - OLD -->
    <section v-if="false" id="consejos-old">
      <img
        src="https://media.gq-magazine.co.uk/photos/6362959fbe9ba83d95a690ca/master/pass/Andrew-Garfield-is-the-best-spiderman.jpg"
        alt=""
        class="section-bg"
      />
      <div class="section-content">
        <h2>Consejos prácticos</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti
          ipsum adipisci molestiae voluptate officiis natus ab cupiditate
          quibusdam laboriosam quidem inventore eligendi, reprehenderit harum
          illum alias architecto. Culpa, aliquid ullam?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae,
          necessitatibus ab dolor error perspiciatis excepturi incidunt sequi
          voluptatem, eos corrupti ipsam nihil sapiente commodi nisi illo ea.
          Voluptates, necessitatibus. Facilis!
        </p>
      </div>
    </section>
    <!-- Asistencia -->
    <section id="asistencia">
      <div class="section-content">
        <AsistenciaView></AsistenciaView>
      </div>
    </section>
    <!-- musica -->
    <section id="musica">
      <div class="section-content">
        <h2>La música la pones tú</h2>
        <p>
          Queremos que puedas disfrutar al máximo de todo el día... incluido el
          baile. Si hay alguna canción que para tí sea imprescindible, por favor
          apúntala. ¡Intentaremos que no falte ninguna!
        </p>

        <SubmitSong />
      </div>
    </section>
    <!-- lista boda -->
    <section id="lista-boda">
      <div class="section-content">
        <h2>Lista de Boda</h2>
        <p>
          Sabemos que la logística es complicada, por eso lo que más agradecemos
          es que podáis estar este día tan especial con nosotros.
        </p>
        <p>
          Si además de vuestra presencia queréis tener un detalle con nosotros,
          os dejamos nuestro número de cuenta:
        </p>

        <p style="cursor: pointer" @click="copyIban()">
          {{ iban }}
          <v-btn icon @click="copyIban()">
            <v-icon small color="#42634b">mdi-content-copy</v-icon>
          </v-btn>
          <span v-if="copied" style="font-size: 80%; font-family: monospace">
            ¡Copiado!
          </span>
        </p>

        <p>¡Muchas gracias!</p>
      </div>
    </section>
    <!-- contraportada -->
    <section id="home">
      <div class="hero-2"></div>

      <div class="section-title" style="top: 80%">
        <h1>{{ pareja }}</h1>
        <p>{{ fecha }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import SubmitSong from "@/components/SubmitSong.vue";

import CollapsibleH3 from "@/components/CollapsibleH3.vue";
import AsistenciaView from "./AsistenciaView.vue";

export default {
  components: {
    SubmitSong,
    CollapsibleH3,
    AsistenciaView,
  },
  data() {
    return {
      lang: "",
      pareja: "Fati e Ignacio",
      fecha: "17 Mayo 2025",
      gerona: "Gerona",
      consejos_tab: -1,
      copied: false,
      iban: "ES2515830001139160106796",
    };
  },
  methods: {
    translate() {
      this.gerona = this.gerona == "Gerona" ? "Girona" : "Gerona";
    },
    onTabClick(tab) {
      if (this.consejos_tab == tab) this.consejos_tab = -1;
      else this.consejos_tab = tab;
    },
    async copyIban() {
      this.copied = false;

      this.copyText(this.iban);

      //small timeout so if you press twice it feels like re-copying
      setTimeout(() => {
        this.copied = true;
      }, 100);

      //remove the "copied" message after 3 secs
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
    copyText(text) {
      // Get the text field

      //Create a text area
      let el = document.createElement("textarea");
      el.value = text;

      // Select the text field
      el.select();
      el.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(el.value);

      // Remove element
      el.remove();
    },
  },
  mounted() {
    this.lang = window.navigator.userLanguage || window.navigator.language;
    if (this.lang == "ca-ES") {
      this.gerona = "Girona";
    }
  },
};
</script>
