<template>
  <div>
    <div v-if="!haveSuggestion">
      <p class="link" @click="haveSuggestion = !haveSuggestion">
        Sugerir canciones
      </p>
    </div>
    <div v-if="haveSuggestion" style="margin-bottom: 50px">
      <v-text-field
        label="Link a Spotify"
        outlined
        v-model="givenSongUrl"
        :rules="spotifyTrack"
        clearable
        hide-details
      ></v-text-field>

      <!-- song -->
      <div v-if="validatedSongUrl">
        <div
          style="
            left: 0;
            width: 100%;
            height: 90px;
            position: relative;
            margin-top: 10px;
          "
        >
          <iframe
            :src="validatedSongUrl"
            style="
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              border: 0;
            "
            allowfullscreen
            allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
          ></iframe>
        </div>
        <p style="color: darkgreen" v-if="false">
          Para que sepamos quien eres, ¿nos dices tu nombre?
        </p>
        <br />

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              label="Tu nombre y apellidos"
              hide-details
              outlined
              v-model="suggestedBy"
              clearable
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              outlined
              v-model="suggestedFor"
              :items="['Cocktail', 'Discoteca', 'Ambas', 'Otro']"
              label="Sugerencia para..."
            >
            </v-select>
          </v-col>
        </v-row>
        <v-btn
          :block="!mdAndUp"
          :disabled="!validatedSongUrl"
          depressed
          @click="sendSong()"
          class="mt-2"
          color="#42634b"
          style="color: white"
          >Enviar sugerencia</v-btn
        >
      </div>
    </div>
    <div v-show="!verPlaylist">
      <p class="link" @click="verPlaylist = !verPlaylist">Ver Playlist</p>
    </div>

    <!-- playlist -->
    <div v-if="verPlaylist">
      <hr v-if="false" style="margin: 20px 40px" />
      <h3>Esta es nuestra playlist para el baile (por ahora)</h3>
      <div style="padding-top: 10px">
        <div style="left: 0; width: 100%; height: 450px; position: relative">
          <iframe
            src="https://open.spotify.com/embed/playlist/3AXJeW7KbPXvSBRMiaUaAP?utm_source=oembed"
            style="
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              border: 0;
            "
            allowfullscreen
            allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture;"
          ></iframe>
        </div>
        <p v-if="false" class="link" @click="verPlaylist = !verPlaylist">
          Ocultar
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    validatedSongUrl() {
      if (!this.givenSongUrl) return "";
      let match = this.givenSongUrl.match(/.*open.spotify.com\/track.*/g);
      if (match && match.length) {
        return match[0].replace("/track/", "/embed/track/");
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      haveSuggestion: true,
      givenSongUrl: "",
      suggestedBy: "",
      suggestedFor: undefined,
      verPlaylist: true,
      spotifyTrack: [(value) => /.*open.spotify.com\/track.*/g.test(value)],
    };
  },

  methods: {
    async sendSong() {
      const url =
        "https://k7pgitzaqmupbfp7mbmvmkk3ne0avhrx.lambda-url.eu-west-3.on.aws";
      const apiKey = "adfj38-gh3i-lo3l2hg@la54-8g$padf"; //yeah, not safest thing in the world, but it's a way to change / stop if issues.

      let x = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          apiKey: apiKey,
          suggestedBy: this.suggestedBy,
          givenSongUrl: this.givenSongUrl,
          suggestedFor: this.suggestedFor,
        }),
      });

      if (x.ok) {
        alert("Recibido! Echamos un ojo y la añadimos pronto. ¡Gracias!");
      } else {
        alert("Ouch. Ha habido un error. Pruebas porfa más tarde?");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 1px solid #ccc;
}
</style>
